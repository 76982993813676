<template>
  <div class="row" v-if="initialTabTitle">
    <div class="col-xl-8">
      <card>
        <template slot="header">
          <h5 class="card-title">Laporan</h5>
        </template>
        <template slot="default">

          <div>
            <tabs vertical flex-layout
              class="kf-tabs"
              :value="initialTabTitle"
              @tab-activated="onTabActivated"
              vertical-nav-classes=""
              vertical-tab-content-classes="px-3"
              vertical-tab-nav-classes="text-left"
              tab-nav-item-classes="pr-3">
              <tab-pane v-for="(tabItem, tabIndex) in tabs" :key="tabIndex" :title="tabItem.title">
                <div class="mb-4" v-for="(item, index) in filteredTabMenus(tabItem.menus)" :key="index">
                  <h6>
                    <router-link :to="{ path: item.path, query: item.query }" :class="item.titleClass">
                      <i v-if="item.titleIcon" class="title-icon" :class="item.titleIcon"></i>
                      {{item.title}}
                    </router-link>
                  </h6>
                  <p>{{item.description}}</p>
                </div>
              </tab-pane>
            </tabs>
          </div>

        </template>
      </card>
    </div>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { Card, Tabs, TabPane } from '../../UIComponents'
  import * as urlConstant from '../../../constants/urlConstant'
  import * as userRoleEnum from '../../../enums/userRoleEnum'

  export default {
    components: {
      Card,
      Tabs,
      TabPane,
    },
    data () {
      return {
        initialTabTitle: '',
        tabs: [
          {
            title: 'Keuangan',
            key: 'financial',
            menus: [
              {
                title: 'Laba-rugi',
                description: 'Menampilkan setiap tipe transaksi, jumlah total pendapatan dan pengeluaran. (Fitur belum tersedia)',
                titleClass: 'text-muted',
                path: urlConstant.WEB_URL_REPORTS,
                query: { tab: 'financial' }
              },
              {
                title: 'Neraca',
                description: 'Menampilan daftar aset, hutang dan modal. (Fitur belum tersedia)',
                titleClass: 'text-muted',
                path: urlConstant.WEB_URL_REPORTS,
                query: { tab: 'financial' }
              },
              {
                title: 'Arus Kas',
                description: 'Menampilkan aliran kas masuk dan keluar perusahaan dalam suatu periode. (Fitur belum tersedia)',
                titleClass: 'text-muted',
                path: urlConstant.WEB_URL_REPORTS,
                query: { tab: 'financial' }
              },
            ]
          },
          {
            title: 'Penjualan',
            key: 'sales',
            menus: [
              {
                title: 'Penjualan',
                description: 'Menampilkan daftar kronologis dari semua invoice untuk rentang tanggal yang dipilih.',
                path: urlConstant.WEB_URL_REPORT_SALES
              },
              {
                title: 'Penjualan Selektif',
                description: 'Menampilkan daftar kronologis dari semua invoice yang sudah diseleksi untuk rentang tanggal yang dipilih.',
                path: urlConstant.WEB_URL_REPORT_SALES_ALT,
                roleGroup: userRoleEnum.ROLE_GROUP_ADMIN_AND_ABOVE
              },
              {
                titleIcon: 'nc-icon nc-settings-gear-65',
                titleClass: 'text-warning',
                title: 'Daftar Penjualan Selektif',
                description: 'Mengatur invoice yang akan ditampilkan pada Laporan Penjualan Selektif.',
                path: urlConstant.WEB_URL_REPORT_SALES_ALT_LIST,
                roleGroup: userRoleEnum.ROLE_GROUP_ADMIN_AND_ABOVE
              },
            ]
          },
          {
            title: 'Pembelian',
            key: 'purchases',
            menus: [
              {
                title: 'Pembelian',
                description: 'Menampilkan daftar kronologis dari semua invoice pembelian untuk rentang tanggal yang dipilih.',
                path: urlConstant.WEB_URL_REPORT_PURCHASES
              },
            ]
          },
          {
            title: 'Produk',
            key: 'products',
            menus: [
              {
                title: 'Stok Kategori',
                description: 'Menampilkan jumlah stok unit terkini per kategori produk.',
                path: urlConstant.WEB_URL_REPORT_PRODUCTS_STOCK_CATEGORIES
              },
              {
                title: 'Mutasi Stok Kategori',
                description: 'Menampilkan mutasi stok per kategori produk dalam rentang periode tertentu.',
                path: urlConstant.WEB_URL_REPORT_PRODUCTS_STOCK_CATEGORY_MUTATIONS
              },
            ]
          },
        ]
      }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        const routeChanged = this.setDefaultRouteQuery()

        if (routeChanged) {
          return
        }
        
        this.setActiveTabFromRoute()
      },
      setDefaultRouteQuery () {
        let isChanged = false
        const query = _.cloneDeep(this.$route.query)

        if (!query.tab || !this.tabs.find(x => x.key == query.tab)) {
          query.tab = this.tabs[0].key
          isChanged = true
        }
        
        if (isChanged) {
          this.$router.replace({ path: this.$route.path, query: query })
        }

        return isChanged
      },
      setActiveTabFromRoute () {
        const tabKey = this.$route.query.tab
        const tab = this.tabs.find(x => x.key === tabKey) || this.tabs[0]

        this.initialTabTitle = tab.title
      },
      onTabActivated (tabEl) {
        if (!tabEl) {
          return
        }

        const tab = this.tabs.find(x => x.title === tabEl.title)
        if (!tab) {
          return
        }

        const query = _.cloneDeep(this.$route.query)
        if (query.tab === tab.key) {
          return
        }

        query.tab = tab.key

        this.$router.push({ path: this.$route.path, query: query })
      },
      filteredTabMenus (menus) {
        if (!menus) {
          return []
        }
        
        return menus.filter(menu => this.canNavigate(menu.roleGroup))
      },
      canNavigate (roleGroup) {
        if (!roleGroup) {
          return true
        }

        const userRole = this.$store.getters.getUserRole
        return roleGroup.includes(userRole)
      },
    },
    watch: {
      $route (to, from) {
        if (to.fullPath !== from.fullPath) {
          this.init()
        }
      }
    },
  }
</script>
<style scoped lang="scss">
  .title-icon {
    position: relative;
    top: 2px;
  }
</style>
